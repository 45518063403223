import React from 'react';
import { navigate } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';
import { createChatroomSelectorById } from '../../../reducers/chatrooms';
import { getChatroom } from '../../../actions/chatrooms';
import { getMenu, setMenuQuantity, clearMenu } from '../../../actions/menus';
import { addLabItem } from '../../../actions/lab';
import { getAddOnBenefit, getUrlParams } from '../../../helpers/location';
import { rateChat, buildUrlWithParams } from '../../../apis';
import Helmet from 'react-helmet';
import Box from '@material-ui/core/Box';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Button from '@material-ui/core/Button';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Table from '../../../components/Table';
import Cell from '../../../components/Table/cell';
import Layout from '../../../components/Layout';
import RatingDialog from '../../../components/RatingDialog';
import PrescriptionItem from '../../../components/PrescriptionItem';
import PrescriptionLabItem from '../../../components/PrescriptionLabItem';
import ReceiptDownloadButton from '../../../components/Button/ReceiptDownloadButton';


export default ({ location }) => {
  const [rateDialog, setRateDialog] = React.useState(false);
  const params = getUrlParams(location.search);
  const { id } = params;
  const dispatch = useDispatch();
  const chatroom = useSelector(state => createChatroomSelectorById(state, id));

  React.useEffect(() => {
    if (id) {
      dispatch(getChatroom(false, id));
    }
  }, [id]);

  React.useEffect(() => {
    if (chatroom) {
      if (!chatroom.rating) {
        setRateDialog(true);
      }
    }
  }, [chatroom]);
  
  const getAddOnBenefitData = (field) => {
    const closed = chatroom?.closed;
    const appointment = chatroom?.appointment;

    if (!closed || !appointment) return undefined;

    const addOnBenefit = appointment.add_on_benefit;
    if (!addOnBenefit) return undefined;

    return addOnBenefit[field];
  }

  const handleRating = async (score, text) => {
    try {
      if (score) {
        await rateChat(id, { score, text });
      }
      setRateDialog();
    } catch (e) {
    }
  }

  const checkoutDrugs = async () => {
    await dispatch(clearMenu());
    
    if (chatroom?.closed?.prescriptions.length) {
      const closed = chatroom?.closed;
      const addOnBenefitText = getAddOnBenefitData("add_on_benefit");
      const card = getAddOnBenefitData("card");

      for (var i = 0; i < closed?.prescriptions.length; i++) {
        const { product, quantity = 1 } = closed?.prescriptions[i];
        await dispatch(getMenu(product.id, closed?.tenant?.id));
        dispatch(setMenuQuantity(product.id, quantity));
      }

      const url = "/drugStore/checkout";
      const params = {
        chatroom: id,
        card: card,
        add_on_benefit: addOnBenefitText
      };
      const returnUri = buildUrlWithParams(url, params);
      navigate(`/otp2?noSkip=1&returnUri=${returnUri}`);
    }
  }

  const checkoutLab = async () => {
    if (chatroom?.closed?.lab_packages.length) {
      const closed = chatroom?.closed;
      for (var i = 0; i <  closed?.lab_packages.length; i++) {
        const item = closed?.lab_packages[i];
        dispatch(addLabItem(item.labpackage));
      }
      navigate(`/otp2?noSkip=1&returnUri=/labStore/checkout?chatroom=${id}`);
    }
  }

  if (!chatroom) return false;

  const { staff, customer, is_close, closed } = chatroom;

  if (!is_close) return false;

  return (
    <Layout hideAppBar>
      <Helmet>
        <title>สรุปการพบแพทย์ - MorOnline</title>
      </Helmet>
      <RatingDialog open={rateDialog} onSubmit={handleRating} />
      <Box p={2}>
        {/* <Box position="absolute" display="flex" flexDirection="column" p={1} alignItems="center" right={0} width={180}>
          <img src="/img/qr.png" height="90"/>
          <small style={{ fontSize: '0.6em' }}>Ref no.<br/>{chatroom.id}</small>
        </Box> */}
        <h2>สรุปการพบแพทย์</h2>
        <TableRow>
          <Cell>วันที่</Cell>
          <Cell>{new Date(chatroom.last_updated).toLocaleDateString()}</Cell>
        </TableRow>
        <h3>ข้อมูลแพทย์</h3>
        <Table>
          <TableBody>
            <TableRow>
              <Cell>ชื่อ</Cell>
              <Cell>{`${staff.first_name} ${staff.last_name}`}</Cell>
            </TableRow>
          </TableBody>
        </Table>
        <h3>ผลการตรวจ</h3>
        <Table>
          <TableBody>
            <TableRow>
              <Cell>วันที่พบแพทย์</Cell>
              <Cell>{new Date(chatroom.last_updated).toLocaleDateString()}</Cell>
            </TableRow>
            <TableRow>
              <Cell>ชื่อ</Cell>
              <Cell>{`${customer.first_name} ${customer.last_name}`}</Cell>
            </TableRow>
            {
              closed?.disease_risk_text && (
                <TableRow>
                  <Cell>โรค</Cell>
                  <Cell>{closed.disease_risk_text}</Cell>
                </TableRow>
              )
            }
            {
              closed?.illness_text && (
                <TableRow>
                  <Cell>อาการ</Cell>
                  <Cell>{closed.illness_text}</Cell>
                </TableRow>
              )
            }
            {
              closed?.treatment_text && (
                <TableRow>
                  <Cell>การรักษา</Cell>
                  <Cell>{closed.treatment_text}</Cell>
                </TableRow>
              )
            }
            {
              closed?.suggestion_text && (
                <TableRow>
                  <Cell>ข้อแนะนำ</Cell>
                  <Cell>{closed.suggestion_text}</Cell>
                </TableRow>
              )
            }
          </TableBody>
        </Table>
        {
          !!closed?.prescriptions?.length && (
            <>
              <h3>รายการยา</h3>
              {
                closed.out_of_stock_prescriptions.map((val, index) => {
                  return (
                    <PrescriptionItem {...val} isOutOfStock key={index} />
                  );
                })
              }
              {
                closed.prescriptions.map((val, index) => {
                  return (
                    <PrescriptionItem {...val} key={index} />
                  );
                })
              }
              <Box mt={1}>
                <Button
                  startIcon={<ShoppingCartIcon />}
                  variant="contained"
                  color="primary"
                  style={{ width: '100%' }}
                  onClick={checkoutDrugs}
                >
                  ซื้อยา
                </Button>
              </Box>
              {chatroom.receipt &&
                <Box mt={1}>
                  <ReceiptDownloadButton
                    fileUrl={chatroom.receipt.fileUrl}
                    filename={chatroom.receipt.id} />
                </Box>
              }
            </>
          )
        }
        {
          !!closed?.lab_packages?.length && (
            <>
              <h3>รายการ Lab</h3>
              {
                closed.lab_packages.map((val, index) => {
                  return (
                    <PrescriptionLabItem {...val} key={index} />
                  );
                })
              }
              <Box mt={1}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={checkoutLab}
                >
                  จองรายการตรวจ Lab
                </Button>
              </Box>
            </>
          )
        }
      </Box>
    </Layout>
  );
}
