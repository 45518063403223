import React from 'react';
import Box from '@material-ui/core/Box';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Cell from '../Table/cell';

export default (props) => {
  const { labpackage: { name, prerequisite, id } } = props;
  return (
    <Paper variant="outlined">
      <Box px={1} py={0.2}>
        <TableRow>
          <Cell>
            {name}
          </Cell>
        </TableRow>
        <TableRow>
          <Cell>
            <small>
              {prerequisite}
            </small>
          </Cell>
        </TableRow>
      </Box>
    </Paper>
  )
}
